import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';

import notify from 'notify';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Input from 'components/Input';
import StripeInput from 'components/StripeInput';

import { REGEXP } from 'consts';
import Context from '../context';

const AddCardForm = ({ onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { disablePayment, addNewCard } = useContext(Context);
  const stripeLoaded = Boolean(stripe && elements);
  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit
  } = useForm();

  const submit = async values => {
    try {
      if (!stripe || !elements) throw Error('Stripe is not loaded');
      const cardNumber = elements.getElement(CardNumberElement);

      if (!cardNumber) throw Error('No card number element');

      const { token, error } = await stripe.createToken(cardNumber, {
        address_zip: values.zipcode
      });

      if (error) throw Error(error.message);
      if (!token) throw Error('No token');

      await addNewCard({ tokenId: token.id });
      onSubmit();
    } catch (err) {
      notify(err.message);
    }
  };

  const handleStripeInputChange = e => {
    if (e.error) {
      setError(e.elementType, { type: 'srtipe', message: e.error.message });
    } else {
      clearErrors(e.elementType);
    }
  };

  return (
    <StyledAddCardForm className="addCard-form" onSubmit={handleSubmit(submit)}>
      <div className="addCard-form__container">
        <StripeInput
          type="number"
          label="Card Number"
          className="addCard-form__item addCard-form__number"
          placeholder="Enter Card Number"
          onChange={handleStripeInputChange}
        />
      </div>
      <div className="addCard-form__container">
        <StripeInput
          type="expiry"
          label="Expiration Date"
          className="addCard-form__item addCard-form__expiry"
          placeholder="MM/YY"
          onChange={handleStripeInputChange}
        />
        <StripeInput
          type="cvc"
          label="CVC"
          placeholder="CVC"
          className="addCard-form__item addCard-form__cvc"
          onChange={handleStripeInputChange}
        />
        <Input
          {...register('zipcode', {
            required: 'Required',
            pattern: {
              value: REGEXP.ZIP_CODE,
              message: 'Invalid ZIP code'
            }
          })}
          error={errors.zipcode?.message}
          label="ZIP Code"
          placeholder="Enter ZIP Code"
          className="addCard-form__item addCard-form__zipcode"
          data-cy="input_zip"
        />
      </div>
      <Button className="addCard-form__pay-btn" disabled={!stripeLoaded || disablePayment}>
        Save Card
      </Button>
    </StyledAddCardForm>
  );
};

// export default injectStripe(AddCardForm);
export default AddCardForm;

const StyledAddCardForm = styled.form`
  .addCard-form {
    &__container {
      display: flex;
      margin: 0 0 16px;
    }
    &__item ~ * {
      margin-left: 12px;
    }
    &__number {
      width: 100%;
    }
    &__expiry,
    &__cvc {
      width: 93px;
    }
    &__zipcode {
      width: 126px;
    }
    &__pay-btn {
      width: 100%;
      height: 40px;
    }
  }
`;
