import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, Dropdown, DropdownOption } from '@xchange/uikit';
import { getVendors, getUserVendors, updateUserVendors } from 'api/vendors';

const mapVendorToDropdownOption = (vendor: Vendor): DropdownOption => ({
  value: vendor.id,
  text: vendor.name
});

const getDropdownText = (name, length, options) => {
  if (!length) return 'Select';
  if (length === options.length) return `All ${pluralize(name, length)}`;
  return `${length} ${pluralize(name, length)}`;
};

const Vendors = () => {
  const {
    control,
    formState: { isDirty, dirtyFields },
    setValue,
    reset,
    getValues,
    handleSubmit
  } = useForm();
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const attorneyOptions = vendors
    .filter(item => item.category === 'Attorney')
    .map(mapVendorToDropdownOption);
  const lendersOptions = vendors
    .filter(item => item.category === 'Lender')
    .map(mapVendorToDropdownOption);

  const submit = async values => {
    try {
      const data: any[] = [];
      if (dirtyFields.attorneys) data.push({ type: 'Attorney', preferred: values.attorneys });
      if (dirtyFields.lenders) data.push({ type: 'Lender', preferred: values.lenders });
      await updateUserVendors(data);
      reset(getValues());
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const vendors = await getVendors();
        setVendors(vendors);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();

    (async () => {
      try {
        const { attorneys, lenders } = await getUserVendors();
        const attorneyIds = attorneys.map(item => item.id) || [];
        const lenderIds = lenders.map(item => item.id) || [];
        setValue('attorneys', attorneyIds);
        setValue('lenders', lenderIds);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [setValue]);

  return (
    <StyledVendors className="vendors" onSubmit={handleSubmit(submit)}>
      <p className="vendors__description">Please select attorneys you are affiliated with.</p>

      <Controller
        control={control}
        name="attorneys"
        defaultValue={[]}
        render={({ field: { value, ...field }, formState: { errors } }) => (
          <Dropdown
            label="Attorneys"
            value={value}
            options={attorneyOptions}
            error={errors.attorneys?.message}
            text={getDropdownText('attorney', value?.length, attorneyOptions.length)}
            multiple
            search
            tags
            {...field}
          />
        )}
      />

      <p className="vendors__description">Please select lenders you are affiliated with.</p>

      <Controller
        control={control}
        name="lenders"
        defaultValue={[]}
        render={({ field: { value, ...field }, formState: { errors } }) => (
          <Dropdown
            label="Lenders"
            value={value}
            options={lendersOptions}
            error={errors.lenders?.message}
            text={getDropdownText('lender', value?.length, lendersOptions.length)}
            multiple
            search
            tags
            {...field}
          />
        )}
      />

      <Button className="save-btn" disabled={!isDirty}>
        Save Changes
      </Button>
    </StyledVendors>
  );
};

export default Vendors;

const StyledVendors = styled.form`
  .vendors {
    &__description {
      margin: 0 0 24px;
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
      & ~ p {
        margin: 48px 0 24px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    .dropdown {
      width: 100%;
    }
  }

  .save-btn {
    float: right;
    margin-top: 40px;
  }
`;
