import React, { useContext, useRef, useState } from 'react';

import notify from 'notify';
import styled from '@emotion/styled';
import Icon from 'components/Icon';
import { AuthContext } from 'auth';
import avatarPlaceholder from './avatarPlaceholder.svg';
import { updateProfile } from '../api';
import Context from '../context';

const allowedImageTypes = new Set(['image/png', 'image/jpg', 'image/jpeg', 'image/gif']);

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

interface ProfileInfoProps {
  data: Profile;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ data }) => {
  const { profile, setProfile } = useContext(AuthContext);
  const { changeProfileData } = useContext(Context);
  const { id, name, email } = data;
  const [avatar, setAvatar] = useState(data.avatar);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadNewAvatar = async (file: File) => {
    try {
      const { avatar } = await updateProfile({ avatar: file });
      const newSrc = await getBase64(file);
      setAvatar(newSrc);
      changeProfileData({ avatar });
      setProfile({ ...profile!, avatar });
      notify('Avatar updated');
    } catch (err) {
      notify('Failed to upload new avatar');
    }
  };

  const handleFileInputChange = e => {
    const file: File = e.target.files[0];
    if (!file) return;
    if (!allowedImageTypes.has(file.type)) {
      notify('Only png/jpg/jpeg/gif files are allowed');
      return;
    }
    fileInputRef.current!.value = '';
    uploadNewAvatar(file);
  };

  const openAvatarUpload = () => {
    fileInputRef.current!.click();
  };

  return (
    <StyledProfileInfo className="profile-info">
      <div className="user-avatar">
        <img src={avatar || avatarPlaceholder} alt="user-avatar-image" />
        <div
          className="update-avatar"
          role="button"
          onClick={openAvatarUpload}
          data-cy="update_avatar"
          tabIndex={0}>
          <Icon name="camera" className="update-avatar__icon" />
          <span className="update-avatar__text">Update</span>
        </div>
        <input
          ref={fileInputRef}
          accept="image/*"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
          data-cy="input_file_avatar"
        />
      </div>
      <div className="user-info">
        <h5 className="user-name">{name}</h5>
        <p className="user-id">Account ID: {id}</p>
        <a href={`mailto:${email}`} className="user-email">
          {data.email}
        </a>
      </div>
    </StyledProfileInfo>
  );
};

const StyledProfileInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 40px;
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  justify-content: center;

  .user-avatar {
    width: 120px;
    height: 120px;
    border-radius: ${props => props.theme.misc.borderRadius};
    margin: 0 24px 0 0;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .user-info {
    overflow: hidden;
  }

  .user-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 4px;
  }
  .user-id,
  .user-email {
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.grayDark};
  }
  .user-id {
    margin: 0 0 4px;
  }
  .user-email {
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .update-avatar {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    &__icon {
      margin: 0 8px 0 0;
    }
    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: white;
    }
  }
`;

export default ProfileInfo;
