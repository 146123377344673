import React from 'react';
import { Router } from '@reach/router';
import ProfilePage from 'views/ProfilePage';
import PrivateRoute from 'components/PrivateRoute';
import Workspace from 'workspace';

const Profile = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/profile" component={ProfilePage} />
      </Router>
    </Workspace>
  );
};

export default Profile;
