import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import Context from '../context';

const getMonthTotal = items => {
  const total =
    items.reduce((acc, curr) => acc + Number(curr.amount) - Number(curr.refundAmount), 0);
  return `$${total}`;
};

const MonthTransactions = ({ month, items, disputeCharge }) => {
  const [transMonthState, setTransMonthState] = useState({});
  const { profileData } = useContext(Context);

  const toggleMonth = i => {
    setTransMonthState({ ...transMonthState, [i]: !transMonthState[i] });
  };

  return (
    <StyledMonthTransactions className="month">
      <div className="monthTitle">
        <div
          className="month__expand"
          onClick={() => toggleMonth(month)}>
          {transMonthState[month] ? '-' : '+'}
        </div>
        <div className="month__name" onClick={() => toggleMonth(month)}>
          {month}
          <span className="month__total">{getMonthTotal(items)}</span>
        </div>
      </div>
      {transMonthState[month] && (
        <ul className="month-transactions">
          {!items.length && (
            <div className="no-transactions">There are no transactions for this period</div>
          )}
          {items.length > 0 &&
            items.map((trans, i) => (
              <li key={i} className="transaction">
                <div>
                  <div className="transaction__name">{profileData?.name || profileData?.email}</div>
                  <div className="transaction__price-and-refund">
                    {!!trans.refundAmount && (
                      <div className="transaction__refunded">Refunded: ${trans.refundAmount}</div>
                    )}
                    <div className="transaction__sum">${trans.amount}</div>
                  </div>
                </div>
                <div>
                  <div className="transaction__id">
                    ID: {trans.id}
                    <button
                      className="transaction__inquiry"
                      onClick={e => disputeCharge(e, { original: trans })}>
                      Charge inquiry
                  </button>
                  </div>
                  <div className="transaction__date">{dayjs.unix(trans.createdOn).format('MM/DD/YYYY')}</div>
                </div>
              </li>
            ))}
        </ul>
      )}
    </StyledMonthTransactions>
  );
};

export default MonthTransactions;

const StyledMonthTransactions = styled.div`
  font-size: 12px;
  line-height: 16px;

  .month {
    &__expand {
      font-weight: bold;
      cursor: pointer;
    }
    &__name {
      width: 100%;
      padding-left: 17px;
      font-weight: 600;
      cursor: pointer;
    }
    &__total {
      float: right;
    }
  }

  .transaction {
    padding: 16px 0;

    & > div {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      & ~ * {
        margin: 10px 0 0;
      }
    }

    & ~ * {
      border-top: 1px solid ${props => props.theme.colors.gray};
    }

    &__refunded {
      padding-right: 10px;
    }

    &__date {
      text-align: right;
    }

    &__refunded,
    &__date,
    &__id {
      color: ${props => props.theme.colors.grayDark};
    }

    &__name {
      font-weight: bold;
    }

    &__price-and-refund {
      display: flex;
    }

    &__inquiry {
      border: none;
      background: none;
      color: ${props => props.theme.colors.red};
      cursor: pointer;

      @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
        display: block;
        font-size: 14px;
        padding-left: 0px;
      }
    }
  }

  .no-transactions {
    color: ${props => props.theme.colors.grayDark};
    padding-bottom: 16px;
  }
`;
