import React from 'react';
import { useForm } from 'react-hook-form';
import notify from 'notify';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import Input from 'components/Input';
import Button from 'components/Button';
import { updatePassword } from '../api';

const PaswordUserSetting = () => {
  const {
    register,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset
  } = useForm();

  const submit = async values => {
    try {
      await updatePassword({ password: values.passwordNew, passwordOld: values.passwordOld });
      reset();
      notify('Password updated');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  return (
    <StyledPaswordUserSetting className="password-user-setting">
      <h5 className="password-user-setting__block-title">Change Password</h5>
      <form className="password-user-setting__form" onSubmit={handleSubmit(submit)}>
        <Input
          {...register('passwordOld', { required: 'Required' })}
          error={errors.passwordOld?.message}
          label="Old Password"
          placeholder="Enter old password"
          type="password"
        />
        <Input
          {...register('passwordNew', { required: 'Required' })}
          error={errors.passwordNew?.message}
          label="New Password"
          placeholder="Enter new password"
          type="password"
        />
        <Input
          {...register('passwordNewConfirm', {
            required: 'Required',
            validate: value => value === getValues('passwordNew') || `Passwords don't match`
          })}
          error={errors.passwordNewConfirm?.message}
          label="Confirm New Password"
          placeholder="Confirm new password"
          type="password"
        />
        <Button className="password-user-setting__save-btn" disabled={!isDirty}>
          Save Changes
        </Button>
      </form>
    </StyledPaswordUserSetting>
  );
};

const StyledPaswordUserSetting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .password-user-setting {
    &__block-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      margin: 0 0 12px;
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .input {
        margin: 0 0 20px;
        width: 300px;

        &:last-of-type {
          margin: 0 0 24px;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
          width: 100%;
          margin: 0 0 20px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    .password-user-setting {
      &__form {
        width: 100%;
      }
    }
  }
`;

export default PaswordUserSetting;
