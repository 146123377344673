import React, { useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import useLayout from 'hooks/useLayout';
import useAuthContext from 'hooks/useAuthContext';
import MainLayout from 'components/MainLayout';
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import GeneralUserSetting from './components/GeneralUserSetting';
import CompanyInfo from './components/CompanyInfo';
import PaswordUserSetting from './components/PaswordUserSetting';
import Vendors from './components/Vendors';
import Payments from './components/Payments';
import EmailNotifications from './components/EmailNotifications';
import EmailSignatureForm from './components/EmailSignatureForm';
import ProfileInfo from './components/ProfileInfo';
import Context, { ContextProvider } from './context';

enum TabKeys {
  General = 'general',
  Password = 'password',
  Vendors = 'vendors',
  Payments = 'payments',
  EmailNotifications = 'email-notifications',
  EmailSignature = 'email-signature'
}

type Tab = { key: TabKeys; text: string; };

const ProfilePage = () => {
  const { logout } = useAuthContext();
  const {
    profileData,
    companyInfo,
    isAdmin,
    fetchProfileData,
    fetchTransactionsData,
    fetchPaymentCards
  } = useContext(Context);
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, TabKeys.General) as QueryParamConfig<TabKeys>
  });
  const activeTab = query.tab;
  const layout = useLayout();

  const setActiveTab = (tab: TabKeys) => {
    setQuery({ tab }, 'replaceIn');
  };

  const tabs: Tab[] = useMemo(
    () =>
      [
        { key: 'general', text: 'General' },
        { key: 'password', text: 'Password' },
        { key: 'vendors', text: 'Vendors' },
        { key: 'payments', text: 'Payments' },
        { key: 'email-notifications', text: 'Email Notifications' },
        { key: 'email-signature', text: 'Email Signature' }
      ].filter(item => {
        if (!isAdmin && item.key === 'payments') return false;
        return true;
      }) as Tab[],
    [isAdmin]
  );

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case 'general':
        if (!profileData) return null;
        return (
          <>
            <ProfileInfo data={profileData} />
            <GeneralUserSetting defaultValues={profileData} />
            {companyInfo && <CompanyInfo defaultValues={companyInfo} />}
          </>
        );
      case 'password':
        return <PaswordUserSetting />;
      case 'vendors':
        return <Vendors />;
      case 'payments':
        return <Payments />;
      case 'email-notifications':
        return <EmailNotifications />;
      case 'email-signature':
        return <EmailSignatureForm />;
      default:
        return null;
    }
  }, [activeTab, companyInfo, isAdmin, profileData]);

  useEffect(() => {
    if (!profileData) fetchProfileData();
    if (activeTab === 'payments') {
      fetchPaymentCards();
      fetchTransactionsData();
    }
  }, [activeTab, profileData, fetchProfileData, fetchPaymentCards, fetchTransactionsData]);

  return (
    <MainLayout>
      <StyledProfilePage className="profile-page">
        {(layout === 'desktop' || layout === 'tablet') && (
          <div className="profile-page__header">
            <h4 className="profile-page__title">Profile</h4>
            <StyledTabs
              activeTab={activeTab}
              onSelect={(_e, value) => setActiveTab(value.key)}
              items={tabs}
            />
            <Button className="profile-page__logout-btn" onClick={logout}>
              Log Out
            </Button>
          </div>
        )}
        {layout === 'mobile' && (
          <>
            <div className="profile-page__header">
              <h4 className="profile-page__title">Profile</h4>
              <Button className="profile-page__logout-btn" onClick={logout}>
                Log Out
              </Button>
            </div>
            <div className="profile-page__tabs">
              <StyledTabs
                activeTab={activeTab}
                onSelect={(_e, value) => setActiveTab(value.key)}
                items={tabs}
              />
            </div>
          </>
        )}
        {tabContent}
      </StyledProfilePage>
    </MainLayout>
  );
};

export default props => (
  <ContextProvider>
    <ProfilePage {...props} />
  </ContextProvider>
);

const StyledProfilePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .profile-page {
    &__header {
      .tab-item {
        font-weight: 600;
      }
    }
    &__tabs {
      width: 100%;
      overflow-x: auto;
      background-color: #f1f1f1;
      border-radius: 3px;
      .tabs {
        flex-wrap: nowrap;
        background: none;
      }
      .tab-item {
        flex-shrink: 0;
        font-weight: 600;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 40px;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
    }
    &__logout-btn {
      min-width: 100px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .profile-page {
      &__header {
        margin: 0 0 16px;
      }
      &__tabs {
        margin: 0 0 32px;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  display: inline-flex;
`;
