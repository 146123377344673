import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import Button from 'components/Button';
import Input from 'components/Input';
import MaskInputController from 'components/MaskInputController';
import notify from 'notify';
import { updateProfile } from '../api';
import Context from '../context';

interface GeneralUserSettingProps {
  defaultValues: Profile;
}

const GeneralUserSetting: React.FC<GeneralUserSettingProps> = ({ defaultValues }) => {
  const { changeProfileData } = useContext(Context);
  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    formState
  } = useForm({
    defaultValues,
    mode: 'onChange'
  });
  const hasErrors = Object.keys(errors).length > 0;

  const submit = async values => {
    try {
      const data = await updateProfile(values);
      changeProfileData(data);
      reset(getValues());
      notify('Profile changes saved');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  return (
    <StyledGeneralUserSetting className="general-user-setting">
      <h5 className="general-user-setting__block-title">Contact Information</h5>
      <form className="general-user-setting__form" onSubmit={handleSubmit(submit)}>
        <Input
          {...register('name', { required: 'Required' })}
          error={errors.name?.message}
          label="Full Name"
          placeholder="Enter Full Name"
        />
        <MaskInputController
          control={control}
          name="phone"
          rules={{ required: 'Required', validate: value => !value?.includes('_') }}
          mask="+1 (999) 999-9999"
          error={Boolean(errors.phone)}
          label="Cell Phone"
          placeholder="Enter Cell Phone"
        />
        <Input
          {...register('brokerMlsId', { required: 'Required' })}
          error={errors.brokerMlsId?.message}
          label="MLS ID"
          placeholder="Enter MLS ID"
        />
        <MaskInputController
          control={control}
          name="phoneOffice"
          rules={{ required: 'Required', validate: value => !value?.includes('_') }}
          mask="+1 (999) 999-9999"
          error={Boolean(errors.phoneOffice)}
          label="Office Line"
          placeholder="Enter Office Line"
        />
        <Input
          {...register('stateLicenseId')}
          error={errors.stateLicenseId?.message}
          label="State License ID"
          placeholder="Enter State License ID"
        />
        <br />
        <Button disabled={!formState.isDirty || hasErrors || formState.isSubmitting}>
          Save Changes
        </Button>
      </form>
    </StyledGeneralUserSetting>
  );
};

const StyledGeneralUserSetting = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .general-user-setting {
    &__block-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      margin: 0 0 12px;
    }
    &__form {
      .input {
        margin: 0 20px 20px 0;
        width: 300px;

        &:last-of-type {
          margin: 0 0 20px;
        }
        @media (max-width: ${props => props.theme.breakpoints.sm}) {
          width: 100%;
          margin: 0 0 20px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
    .general-user-setting {
      &__info-block {
        width: 100%;
      }
    }
  }
`;

export default GeneralUserSetting;
