import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Context from '../context';

const EmailNotifications = () => {
  const { profileData, changeEmailNotifications } = useContext(Context);
  const defaultValues = { emailNotifications: Boolean(profileData?.deadlinesNotifications) };
  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (profileData) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const submit = ({ emailNotifications }) => {
    changeEmailNotifications(emailNotifications);
  };

  return (
    <StyledEmailNotifications className="email-notifications">
      <p className="email-notifications__description">
        Set notification preferences for your account.
      </p>
      <form className="email-notifications__form" onSubmit={handleSubmit(submit)}>
        <Checkbox
          {...register('emailNotifications')}
          label="Daily Summary of Upcoming Deadlines"
          data-cy="checkbox_daily_summary"
        />
        <Button
          disabled={!formState.isDirty || formState.isSubmitSuccessful}
          data-cy="button_submit">
          Save Changes
        </Button>
      </form>
    </StyledEmailNotifications>
  );
};

export default React.memo(EmailNotifications);

const StyledEmailNotifications = styled.div`
  .email-notifications {
    &__description {
      margin: 0 0 24px;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme.colors.grayDark};
    }

    &__form {
      .checkbox {
        margin: 0 0 24px;
      }

      .button {
        display: block;
      }
    }
  }
`;
